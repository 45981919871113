<template lang="pug">
  div.report-container
    card(title='Vehicle Reports')
      .filters.mb-6
        .d-flex.flex-wrap.align-items-center.gap-2.px-2.py-3
          .filter-group.d-flex
            // Filter Type Selector
            select.form-control.filter-item(v-model="filterType" @change="handleFilterChange")
              option(value="daily") Daily Report
              option(value="monthly") Monthly Report
              option(value="yearly") Yearly Report
            
            // Date Filter (for daily)
            input.form-control.filter-item(
              v-if="filterType === 'daily'"
              type="date"
              v-model="selectedDate"
              @change="onDateChange"
            )
            
            // Month Filter (for monthly)
            select.form-control.filter-item(
              v-if="filterType === 'monthly'"
              v-model="selectedMonth"
              @change="onMonthChange"
            )
              option(value="") Select Month
              option(v-for="month in months" :key="month.value" :value="month.value") {{ month.label }}
            
            // Year Filter (for monthly and yearly)
            select.form-control.filter-item(
              v-if="filterType !== 'daily'"
              v-model="selectedYear"
              @change="onYearChange"
            )
              option(value="") Select Year
              option(v-for="year in years" :key="year" :value="year") {{ year }}
  
          .search-export-group.d-flex.gap-3.flex-grow-1.pt-3
            // Search Box
            .input-group
              img.position-absolute(
                src="/icons/search-icon.png"
                alt="Search"
                style="right: 10px; top: 50%; transform: translateY(-50%); width: 18px; height: 18px; z-index: 4"
              )
              input.form-control.pl-4(
                type="text"
                v-model="searchTerm"
                @input="handleSearch"
                placeholder="Search..."
                style=""
              )
            
            // Export Button
            button.btn.btn-outline-primary.export-btn(@click="onExport")
              i.fas.fa-download.mr-2
              span Export
  
      // Loading State
      .loading-overlay(v-if="isLoading")
        .spinner-border.text-primary(role="status")
        .mt-2 Loading data...
  
      // Data Table
      .table-responsive(v-else)
        table.table.table-hover
          thead
            tr
              th(v-for="column in columns" :key="column.field") 
                .d-flex.align-items-center.justify-content-between
                  span {{ column.title }}
                  .sort-icons(v-if="column.sortable" @click="sortBy(column.field)")
                    i.fas.fa-sort
  
          tbody
            tr(v-for="device in paginatedDevices" :key="device.id")
              td {{ formatDate(device.timestamp) }}
              td {{ device.nomorpolisi }}
              td {{ device.namaperangkat }}
              td {{ device.jeniskendaraan }}
              td {{ formatTime(device.timestamp) }}
              td {{ formatNumber(device.fuel) }} L
              td {{ formatNumber(device.distance) }} KM
  
      // Pagination and Totals
      .d-flex.justify-content-between.align-items-center.mt-4
        .totals
          span.mr-4 Total Fuel: {{ totalFuel }} L
          span.mr-4 Total Distance: {{ totalDistances }} KM
        
        .pagination.d-flex.align-items-center
          button.btn.btn-sm.btn-outline-primary.mr-2(
            @click="prevPage"
            :disabled="currentPage === 1"
          ) 
            i.fas.fa-chevron-left
          
          span.mx-2 Page {{ currentPage }} of {{ totalPages }}
          
          button.btn.btn-sm.btn-outline-primary.ml-2(
            @click="nextPage"
            :disabled="currentPage === totalPages"
          )
            i.fas.fa-chevron-right
  </template>

<script>
import axios from "axios";

export default {
  name: "VehicleReport",

  data() {
    return {
      filterType: "daily",
      isLoading: false,
      searchTerm: "",
      selectedDate: this.getTodayDate(),
      selectedMonth: "",
      selectedYear: new Date().getFullYear().toString(), // Ubah ke string
      currentPage: 1,
      perPage: 10,
      sortField: "tanggal",
      sortDirection: "desc",

      // Data
      allDevices: [],
      filteredDevices: [],

      // Totals
      totalFuel: "0.00",
      totalDistances: "0.00",

      // Filter options
      months: [
        { label: "January", value: "01" },
        { label: "February", value: "02" },
        { label: "March", value: "03" },
        { label: "April", value: "04" },
        { label: "May", value: "05" },
        { label: "June", value: "06" },
        { label: "July", value: "07" },
        { label: "August", value: "08" },
        { label: "September", value: "09" },
        { label: "October", value: "10" },
        { label: "November", value: "11" },
        { label: "December", value: "12" },
      ],

      columns: [
        { title: "Tanggal", field: "tanggal", sortable: true },
        { title: "Nomor Polisi", field: "nomorpolisi", sortable: true },
        { title: "Nama Perangkat", field: "namaperangkat", sortable: true },
        { title: "Jenis Kendaraan", field: "jeniskendaraan", sortable: true },
        { title: "Jam", field: "timestamp", sortable: true },
        { title: "Bahan Bakar (L)", field: "fuel", sortable: true },
        { title: "Jarak Tempuh (KM)", field: "distance", sortable: true },
      ],
    };
  },

  computed: {
    years() {
      const currentYear = new Date().getFullYear();
      return Array.from({ length: 5 }, (_, i) => currentYear - i);
    },

    totalPages() {
      return Math.ceil(this.filteredDevices.length / this.perPage);
    },

    paginatedDevices() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredDevices.slice(start, end);
    },
  },

  methods: {
    getTodayDate() {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0); // Reset time part
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
},

    async fetchData() {
      this.isLoading = true;
      try {
        let endpoint = "";
        let params = {};

        switch (this.filterType) {
          case "monthly":
            endpoint = "https://api-my.matadortracker.com/v3/vehicle/report_daily";
            if (this.selectedYear && this.selectedMonth) {
              params = {
                year: this.selectedYear,
                month: this.selectedMonth,
              };
            }
            break;

          case "yearly":
            endpoint = "https://api-my.matadortracker.com/v3/vehicle/report_year";
            params = { year: this.selectedYear };
            break;

          case "daily":
          default:
            endpoint = "https://api-my.matadortracker.com/v3/vehicle/report_list";
            params = { date: this.selectedDate };
            break;
        }

        console.log("Request:", {
          filterType: this.filterType,
          endpoint,
          params,
        });

        const response = await axios.get(endpoint, { params });

        if (response.data?.result?.data) {
          this.allDevices = response.data.result.data;
          this.handleDataUpdate();
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        this.$toast.error("Failed to fetch data");
      } finally {
        this.isLoading = false;
      }
    },

    handleDataUpdate() {
      this.filteredDevices = this.allDevices.map((device) => ({
        ...device,
        tanggal: this.formatDate(device.timestamp),
      }));
      this.updateTotals();
      this.currentPage = 1;
    },

    filterAndSortDevices() {
      let filtered = [...this.allDevices];

      // Apply search filter
      if (this.searchTerm) {
    const searchLower = this.searchTerm.toLowerCase().trim();
    filtered = filtered.filter(
      (device) =>
        String(device.nomorpolisi).toLowerCase().includes(searchLower) ||
        String(device.namaperangkat).toLowerCase().includes(searchLower) ||
        String(device.jeniskendaraan).toLowerCase().includes(searchLower)
    );
  }

      // Apply sorting
      filtered.sort((a, b) => {
    const aVal = a[this.sortField];
    const bVal = b[this.sortField];

    if (this.sortDirection === "asc") {
      return aVal > bVal ? 1 : -1;
    }
    return aVal < bVal ? 1 : -1;
  });

  return filtered;
},

    updateTotals() {
      this.totalFuel = this.filteredDevices
        .reduce((sum, device) => sum + parseFloat(device.fuel || 0), 0)
        .toFixed(2);

      this.totalDistances = this.filteredDevices
        .reduce((sum, device) => sum + parseFloat(device.distance || 0), 0)
        .toFixed(2);
    },

    // Event Handlers
    handleFilterChange() {
      this.selectedMonth = "";
      this.selectedYear = new Date().getFullYear().toString();
      this.selectedDate = this.getTodayDate();
      this.fetchData();
    },

    handleSearch() {
  this.filteredDevices = this.filterAndSortDevices();
},

    sortBy(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortField = field;
        this.sortDirection = "asc";
      }
      this.handleDataUpdate();
    },

    // Navigation
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    // Filter change handlers
    onDateChange() {
  // Ensure date is in UTC
  const [year, month, day] = this.selectedDate.split('-');
  const date = new Date(Date.UTC(year, month - 1, day));
  this.selectedDate = date.toISOString().split('T')[0];
  this.fetchData();
},

    onMonthChange() {
      if (
        this.filterType === "monthly" &&
        this.selectedYear &&
        this.selectedMonth
      ) {
        // Convert to zero-based month (0-11) before sending to API
        const monthNum = parseInt(this.selectedMonth);
        this.selectedMonth = String(monthNum).padStart(2, "0");
        this.fetchData();
      }
    },

    onYearChange() {
      if (this.filterType === "yearly") {
        this.fetchData();
      } else if (this.filterType === "monthly" && this.selectedMonth) {
        this.fetchData();
      }
    },

    // Export
    async onExport() {
      try {
        const response = await axios.get(
          `https://api-my.matadortracker.com/v3/vehicle/export?date=${this.selectedDate}`,
          { responseType: "blob" },
        );

        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `Report-${this.selectedDate}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        this.$toast.error("Export failed. Please try again.");
        console.error("Export failed:", error);
      }
    },

    // Formatters
    formatDate(date) {
      if (!date) return "-";
      // Extract date from timestamp without timezone conversion
      const [datePart] = date.split('T');
      const [year, month, day] = datePart.split('-');
      return `${day}/${month}/${year}`;
    },

    formatTime(timestamp) {
      if (!timestamp) return "-";
      const [, time] = timestamp.split("T");
      return time.substring(0, 5);
    },

    formatNumber(value) {
      if (!value) return "0";
      return parseFloat(value).toFixed(2);
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.report-container {
  .filters {
    select.form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M2 4l4 4 4-4'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px center;
      padding-right: 24px !important;
    }

    select::-ms-expand {
      display: none;
    }

    .filter-group {
      .filter-item {
        min-width: 150px;
        height: 38px;
      }
    }

    .search-export-group {
      .input-group {
        width: 100%;
      }

      input.form-control {
        height: 38px;
      }

      .export-btn {
        height: 38px;
        padding: 0 16px;
        white-space: nowrap;
      }
    }

    select.form-control,
    input.form-control,
    .btn {
      margin: 0 4px;
      border-radius: 4px;
      font-size: 14px;
    }
  }
}
</style>
